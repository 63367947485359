@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(https://fonts.googleapis.com/css?family=Black+Han+Sans&display=swap);
* {
    font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', 'Sans-serif';
    box-sizing: border-box;
}

html, body{
    margin: 0;
    padding: 0;
    height: 100%;
    min-height:100%;
    background-color: #202020;
    color: #fff;
}
.App {
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    position: relative;
}

